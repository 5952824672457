<template>
  <div class="wraper" ref="wraper">
    <div class="canvas-wraper">
      <canvas id="canvas" ref="canvas"></canvas>
    </div>
    <div class="controlPanel">
      <div
        :class="[initIdx == idx ? 'contro-item active' : 'contro-item']"
        v-for="(item, idx) in toolsArr"
        :key="idx"
        @click="handleTools(item, idx)"
      >
        {{ item.name }}
        <!-- <i :class="item.icon">{{ item.name }}</i> -->
      </div>
      <div>
        <input
          id="filereader"
          type="file"
          accept="image/*"
          @change="uploadImage"
        />
      </div>
    </div>
    <img
      id="photo"
      src="../assets/images.png"
      style="width: 500px; display: none"
    />

    <video width="350" height="220" id="video" style="display: none;" control>
      <source src="http://html5demos.com/assets/dizzy.mp4" />
    </video>

    <!-- <div class="download">      
      <button type="button" :disabled="done" @click="downLoadImage">
        轉成base64並預覽
      </button>
      <img :src="imageBase64" v-show="imageBase64 != ''" alt="" />
    </div> -->
  </div>
</template>
<script>
import { fabric } from "fabric";
import io from "socket.io-client";

export default {
  data() {
    return {
      currentTool: "",
      done: false,
      fabricObj: null,
      initIdx: 0,
      toolsArr: [
        {
          name: "pencil",
          icon: "fa fa-pencil",
        },
        {
          name: "line",
          icon: "fas fas-grip-lines",
        },
        {
          name: "arrow",
          icon: "fa fa-arrow-up",
        },
        {
          name: "dashedline",
          icon: "fa fa-xuxian",
        },
        {
          name: "text",
          icon: "fa fa-ziti",
        },
        {
          name: "rectangle",
          icon: "fa fa-square",
        },
        {
          name: "circle",
          icon: "fa fa-circle",
        },
        {
          name: "ellipse",
          icon: "fa fa-ellipse",
        },
        {
          name: "equilateral",
          icon: "fas fa-caret-square-up",
        },
        {
          name: "remove",
          icon: " icon-remove",
        },
        {
          name: "undo",
          icon: " icon-huitui",
        },
        {
          name: "redo",
          icon: " icon-xiangqian",
        },
        {
          name: "reset",
          icon: " icon-reset",
        },
        {
          name: "move",
          icon: " icon-move",
        },
        {
          name: "photo",
          icon: " icon-photo",
        },
        {
          name: "video",
          icon: " icon-video",
        },
      ],
      mouseFrom: {},
      mouseTo: {},
      moveCount: 1,
      doDrawing: false,
      fabricHistoryJson: [],
      mods: 0,
      drawingObject: null,
      drawColor: "#E34F51",
      drawWidth: 2,
      imageBase64: "",
      zoom: window.zoom ? window.zoom : 1,
      socket: {},
      socketLoaded: false,
      roomName: "",
    };
  },
  mounted() {
    this.initCanvas();
  },
  computed: {
    canvasWidth() {
      return window.innerWidth;
    },
    canvasHeight() {
      return window.innerHeight - 150;
    },
  },
  methods: {
    urlForSocket() {
      const protocol = "http://";
      const hostName = "localhost";
      const hostPort = 3000;
      return protocol + hostName + `:${hostPort}`;
    },
    sendCanvas() {
      const canvasAsJSON = this.fabricObj.toJSON();
      if (this.socketLoaded) {
        this.socket.emit("send-board-data", {
          canvasData: canvasAsJSON,
          roomName: this.roomName,
        });
      }
    },
    receiveCanvas(data) {
      if (this.socketLoaded && !!data && !!data.canvasData) {
        this.fabricObj.loadFromJSON(
          data.canvasData,
          this.fabricObj.renderAll.bind(this.fabricObj)
        );
      }
    },
    initCanvas() {
      this.roomName = this.$route.params.room;

      this.fabricObj = new fabric.Canvas("canvas", {
        isDrawingMode: true,
        selectable: false,
        selection: false,
        devicePixelRatio: true,
      });
      this.fabricObj.freeDrawingBrush.color = "#E34F51";
      this.fabricObj.freeDrawingBrush.width = 2;
      this.fabricObj.setWidth(this.canvasWidth);
      this.fabricObj.setHeight(this.canvasHeight);

      this.fabricObjAddEvent();
      fabric.util.requestAnimFrame(this.render);
      
      this.socket = io.connect(this.urlForSocket(), { secure: true });

      this.socket.on("got-connected", () => {
        this.socketLoaded = true;
        this.socket.emit("join-room", {
          roomName: this.roomName,
        });
      });

      this.socket.on("send-board-data", (drawData) => {
        this.receiveCanvas(drawData);
      });
    },
    render() {
      this.fabricObj.renderAll();
      fabric.util.requestAnimFrame(this.render);
    },
    fabricObjAddEvent() {
      this.fabricObj.on({
        "mouse:down": (o) => {
          this.mouseFrom.x = o.pointer.x;
          this.mouseFrom.y = o.pointer.y;
          this.doDrawing = true;
          if (this.currentTool == "text") {
            this.drawText();
          }
          this.sendCanvas();
        },
        "mouse:up": (o) => {
          this.mouseTo.x = o.pointer.x;
          this.mouseTo.y = o.pointer.y;
          this.drawingObject = null;
          this.moveCount = 1;
          this.doDrawing = false;
          this.updateModifications(true);
          this.sendCanvas();
        },
        "mouse:move": (o) => {
          if (!this.doDrawing) {
            return;
          }
          this.moveCount++;
          this.mouseTo.x = o.pointer.x;
          this.mouseTo.y = o.pointer.y;
          this.drawing();
          this.sendCanvas();
        },
        "object:moving": (e) => {
          e.target.opacity = 0.5;
          this.sendCanvas();
        },
        "object:added": () => {},
        "object:modified": (e) => {
          e.target.opacity = 1;
          this.updateModifications(true);
          this.sendCanvas();
        },
        "selection:created": (e) => {
          if (this.currentTool !== "remove") {
            return;
          }

          if (e.target._objects) {
            // 多選刪除
            var etCount = e.target._objects.length;
            for (var etindex = 0; etindex < etCount; etindex++) {
              this.fabricObj.remove(e.target._objects[etindex]);
            }
          } else {
            //單選刪除
            this.fabricObj.remove(e.target);
          }
          this.fabricObj.discardActiveObject();
          this.updateModifications(true);
          this.sendCanvas();
        },
      });
    },
    updateModifications(savehistory) {
      if (savehistory == true) {
        this.fabricHistoryJson.push(JSON.stringify(this.fabricObj));
      }
    },
    undo() {
      let state = this.fabricHistoryJson;
      if (this.mods < state.length) {
        this.fabricObj.clear().renderAll();
        this.fabricObj.loadFromJSON(state[state.length - 1 - this.mods - 1]);
        this.fabricObj.renderAll();
        this.mods += 1;
      }
    },
    redo() {
      let state = this.fabricHistoryJson;
      if (this.mods > 0) {
        this.fabricObj.clear().renderAll();
        this.fabricObj.loadFromJSON(state[state.length - 1 - this.mods + 1]);
        this.fabricObj.renderAll();
        this.mods -= 1;
      }
    },
    transformMouse(mouseX, mouseY) {
      return { x: mouseX / this.zoom, y: mouseY / this.zoom };
    },
    resetObj() {
      this.fabricObj.selectable = false;
      this.fabricObj.selection = false;
      this.fabricObj.skipTargetFind = true;

      if (this.textboxObj) {
        this.textboxObj.exitEditing();
        this.textboxObj = null;
      }
    },
    handleTools(tools, idx) {
      this.initIdx = idx;
      this.currentTool = tools.name;
      
      this.fabricObj.isDrawingMode = false;
      this.resetObj();
      switch (tools.name) {
        case "pencil":
          this.fabricObj.isDrawingMode = true;
          break;
        case "remove":
          this.fabricObj.selection = true;
          this.fabricObj.skipTargetFind = false;
          this.fabricObj.selectable = true;

          this.fabricObj.discardActiveObject().renderAll();
          this.sendCanvas();
          break;
        case "reset":
          this.fabricObj.clear();
          this.sendCanvas();
          break;
        case "redo":
          this.redo();
          this.sendCanvas();
          break;
        case "undo":
          this.undo();
          this.sendCanvas();
          break;
        case "move":
          this.fabricObj.skipTargetFind = false;
          this.fabricObj.selectable = true;
          this.fabricObj.selection = true;
          // this.fabricObj.evented = true;
          // this.fabricObj.forEachObject(object => {
          //     object.selectable = true;
          //     object.evented = true;
          // });
          break;
        case "photo":
          var imgElement = document.getElementById("photo");
          var imgInstance = new fabric.Image(imgElement, {
            left: 100,
            top: 100,
            angle: 0,
            // opacity: 0.75,
            // width: 200,
            // height: 220,
          });
          this.fabricObj.add(imgInstance);
          this.sendCanvas();
          break;
        case "video":
          var videoElement = document.getElementById("video");
          var videoInstance = new fabric.Image(videoElement, {
            left: 100,
            top: 100,
            angle: 0,
            // opacity: 0.75,
            // width: 200,
            // height: 220,
          });
          this.fabricObj.add(videoInstance);
          videoInstance.getElement().play();
          this.sendCanvas();
          break;
        default:
          break;
      }
    },
    drawText() {
      this.textboxObj = new fabric.Textbox(" ", {
        left: this.mouseFrom.x,
        top: this.mouseFrom.y,
        width: 220,
        fontSize: 18,
        fill: this.drawColor,
        hasControls: true,
      });
      this.fabricObj.add(this.textboxObj);
      this.textboxObj.enterEditing();
      this.textboxObj.hiddenTextarea.focus();
      this.updateModifications(true);
    },
    drawing() {
      if (this.drawingObject) {
        this.fabricObj.remove(this.drawingObject);
      }
      let fabricObject = null;
      switch (this.currentTool) {
        case "pencil":
          this.fabricObj.isDrawingMode = true;
          break;
        case "line":
          fabricObject = new fabric.Line(
            [
              this.mouseFrom.x,
              this.mouseFrom.y,
              this.mouseTo.x,
              this.mouseTo.y,
            ],
            {
              stroke: this.drawColor,
              strokeWidth: this.drawWidth,
            }
          );
          break;
        case "arrow":
          fabricObject = new fabric.Path(
            this.drawArrow(
              this.mouseFrom.x,
              this.mouseFrom.y,
              this.mouseTo.x,
              this.mouseTo.y,
              15.5,
              15.5
            ),
            {
              stroke: this.drawColor,
              fill: "rgba(255,255,255,0)",
              strokeWidth: this.drawWidth,
            }
          );
          break;
        case "dashedline":
          fabricObject = this.drawDoshedLine();
          break;
        case "rectangle":
          fabricObject = this.drawRectangle();
          break;
        case "circle":
          fabricObject = this.drawCircle();
          break;
        case "ellipse":
          fabricObject = this.drawEllipse();
          break;
        case "equilateral":
          fabricObject = this.drawTriangle();
          break;
        case "remove":
          break;
        default:
          break;
      }
      if (fabricObject) {
        this.$nextTick(() => {
          this.fabricObj.add(fabricObject);
          this.drawingObject = fabricObject;
        });
      }
    },
    drawDoshedLine() {
      return new fabric.Line(
        [this.mouseFrom.x, this.mouseFrom.y, this.mouseTo.x, this.mouseTo.y],
        {
          strokeDashArray: [10, 3],
          stroke: this.drawColor,
          strokeWidth: this.drawWidth,
        }
      );
    },
    drawCircle() {
      let radius =
        Math.sqrt(
          (this.mouseTo.x - this.mouseFrom.x) *
            (this.mouseTo.x - this.mouseFrom.x) +
            (this.mouseTo.y - this.mouseFrom.y) *
              (this.mouseTo.y - this.mouseFrom.y)
        ) / 2;
      return new fabric.Circle({
        left: this.mouseFrom.x,
        top: this.mouseFrom.y,
        stroke: this.drawColor,
        fill: "rgba(255, 255, 255, 0)",
        radius: radius,
        strokeWidth: this.drawWidth,
      });
    },
    drawTriangle() {
      let height = this.mouseTo.y - this.mouseFrom.y;
      return new fabric.Triangle({
        top: this.mouseFrom.y,
        left: this.mouseFrom.x,
        width: Math.sqrt(Math.pow(height, 2) + Math.pow(height / 2.0, 2)),
        height: height,
        stroke: this.drawColor,
        strokeWidth: this.drawWidth,
        fill: "rgba(255,255,255,0)",
      });
    },
    drawEllipse() {
      let left = this.mouseFrom.x;
      let top = this.mouseFrom.y;
      // let ellipse = Math.sqrt((this.mouseTo.x - left) * (this.mouseTo.x - left) + (this.mouseTo.y - top) * (this.mouseTo.y - top)) / 2;
      return new fabric.Ellipse({
        left: left,
        top: top,
        stroke: this.drawColor,
        fill: "rgba(255, 255, 255, 0)",
        originX: "center",
        originY: "center",
        rx: Math.abs(left - this.mouseTo.x),
        ry: Math.abs(top - this.mouseTo.y),
        strokeWidth: this.drawWidth,
      });
    },
    drawRectangle() {
      return new fabric.Rect({
        left: this.mouseFrom.x,
        top: this.mouseFrom.y,
        width: this.mouseTo.x - this.mouseFrom.x,
        height: this.mouseTo.y - this.mouseFrom.y,
        fill: "rgba(255, 255, 255, 0)",
        stroke: this.drawColor,
        strokeWidth: this.drawWidth,
      });
    },
    drawArrow(fromX, fromY, toX, toY, theta, headlen) {
      theta = typeof theta != "undefined" ? theta : 30;
      headlen = typeof theta != "undefined" ? headlen : 10;

      let angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI,
        angle1 = ((angle + theta) * Math.PI) / 180,
        angle2 = ((angle - theta) * Math.PI) / 180,
        topX = headlen * Math.cos(angle1),
        topY = headlen * Math.sin(angle1),
        botX = headlen * Math.cos(angle2),
        botY = headlen * Math.sin(angle2);
      let arrowX = fromX - topX,
        arrowY = fromY - topY;
      let path = " M " + fromX + " " + fromY;
      path += " L " + toX + " " + toY;
      arrowX = toX + topX;
      arrowY = toY + topY;
      path += " M " + arrowX + " " + arrowY;
      path += " L " + toX + " " + toY;
      arrowX = toX + botX;
      arrowY = toY + botY;
      path += " L " + arrowX + " " + arrowY;
      return path;
    },
    downLoadImage() {
      this.done = true;
      let base64URl = this.fabricObj.toDataURL({
        formart: "png",
        multiplier: 2,
      });
      this.imageBase64 = base64URl;
      this.done = false;
    },
    uploadImage(e) {
      var reader = new FileReader();
      reader.onload = (event) => {
        var imgObj = new Image();
        imgObj.src = event.target.result;
        imgObj.onload = () => {
          var image = new fabric.Image(imgObj);
          image.set({
            left: 250,
            top: 250,
          });
          //image.scale(getRandomNum(0.1, 0.25)).setCoords();
          this.fabricObj.add(image);
          this.sendCanvas();
        };
      };

      reader.readAsDataURL(e.target.files[0]);
      e.target.value = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.wraper {
  position: relative;
  width: 100%;
  height: 100%;
  .canvas-wraper {
    height: 150%;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    background: url("../assets/001.jpg") repeat;
  }
  .controlPanel {
    width: 100%;
    height: 62px;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    .contro-item {
      flex-basis: 100px;
      border-right: 1px solid #dad7d9;
      text-align: center;
      cursor: pointer;
      background: #fefefe;
      i {
        font-size: 38px;
        line-height: 62px;
      }
      &.active {
        background: #e34f51;
        color: #fff;
        border-radius: 3px;
        i {
          font-size: 42px;
        }
      }
    }
  }
  .download {
    img {
      width: 100%;
    }
  }
}
</style>